.customquill {
    border: 2px solid #D1E9FF;
    background-color: white;
    color: black;
    height: 345px; /* Fixed height */
    display: flex;
    flex-direction: column;
  

    .ql-container {
        height: 345px !important;
        overflow-y: auto !important;
    }

  //   &:hover {
  //     border-color: #007bff; /* Blue border on hover */
  // }

//   &:hover {
//     border-color: #007bff;
//     box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Subtle blue glow */
// }

}
